//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { miceService } from "@/service/miceService";
import vScroll from '../../components/mshop/pull-refresh/pull-refresh.vue'
export default {
  components: {
    vScroll
  },
  data() {
    return {
      // counter: 1, //当前页
      // num: 20, // 一页显示多少条
      // pageStart: 0, // 开始页数
      // pageEnd: 0, // 结束页数
      // listdata: [], // 下拉更新数据存放数组
      // downdata: [], // 上拉更多的数据存放数组
      scrollData: {
        noFlag: false //暂无更多数据显示
      },
      dataQuery: {}, //获取地址栏信息
      hospitalList: [], //医院数据
      keyword: null //搜索关键词
    };
  },
  created() {
    this._data.query = this.common.toLowerCase(this.$route.query);
    this.dataQuery = this._data.query;
  },
  mounted: function() {
    this.getHospitals();
  },
  methods: {
    doClose() {
      this.$router.go(-1);
    },
    getHospitals(type) {
      var _this = this;
      this._data.query = this.common.toLowerCase(this.$route.query);
      this._data.query.cityid = this._data.query.cityid || 1;

      var nowData = {
        cityId: this._data.query.cityid,
        proposalType: this._data.query.proposaltype,
        tenantId: this._data.query.tenantid
      };
      if (type == "search" && this.keyword) {
        nowData.keyword = this.keyword;
      } else if (type == "search") {
        alert("请输入医院名称");
      }
      miceService.hospitalQuery(nowData).then(res => {
        _this.hospitalList = res.data;
        _this.keyword = null;
      });
    },
    goPage(item) {
      this.$router.push({
        name: "Index",
        query: {
          cityId: this.dataQuery.cityid,
          hospitalId: item.rowId,
          tenantId: this.dataQuery.tenantid,
          proposalType: this.dataQuery.proposaltype,
          rejecttabs: this.dataQuery.rejecttabs,
          hospitalfixed: this.dataQuery.hospitalfixed,
          fromPage: "hospitals"
        }
      });
    },
    onRefresh(done) {
      // this.getList();
      this.getHospitals();
      done(); // call done
    },
    searchButton(e) {
      var keyCode = e.keyCode;
      if (keyCode == "13") {
        e.preventDefault();
        //请求搜索接口
        this.getHospitals("search");
      }
    }
  }
};
